'use client'

import * as React from 'react'

import { motion } from 'framer-motion'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/Tooltip'

import SocialLinks from './social/SocialLinks'
import Link from 'next/link'

const Hero = () => {
  return (
    <div className="flex flex-col w-full max-w-screen-xl gap-16 px-6 py-16 mx-auto md:py-20 lg:py-36 lg:flex-row">
      <div className="flex flex-col gap-2 lg:w-1/2">
        <h1 className="mb-0 text-4xl font-semibold tracking-tight text-gray-1200 sm:text-5xl">
          Yannis Panagis
        </h1>
        <h2 className="mb-4 text-lg tracking-tight text-gray-1100 sm:text-xl">
          Head of Engineering at Behaviour Lab / London
        </h2>
        <div className="hidden space-x-10 text-sm text-gray-1100 md:flex md:items-center">
          <SocialLinks />
        </div>
      </div>
      <TooltipProvider>
        <div className="lg:w-1/2">
          <motion.div
            className="mb-4 text-lg font-light leading-normal text-gray-1100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.2,
              duration: 0.4,
              type: 'tween',
              ease: 'easeInOut',
            }}
          >
            I work across the stack to build complex data-driven products on the
            web and manage engineering teams. Back in 2018 while I was at{' '}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  href="https://www.imperial.ac.uk/"
                  className="font-normal text-indigo-1000 dark:text-mint-1100"
                >
                  university
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                <p className="w-48">
                  From 2017-2021, I did my MEng in Electrical and Electronic
                  Engineering at Imperial College London.
                </p>
              </TooltipContent>
            </Tooltip>
            , I joined my first hackathon and built an autonomous supermarket
            checkout system with UHF RFID. I immediately jumped in head first
            from that &quot;initial commit&quot; into coding and product
            development. Fast forward to today: I&apos;ve written software for a{' '}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  href="https://www.americanexpress.com/"
                  className="font-normal text-indigo-1000 dark:text-mint-1100"
                >
                  large financial services company
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                <p className="w-52">
                  In 2018, I did my first internship in tech at American
                  Express, where I first started coding in React and JavaScript.
                </p>
              </TooltipContent>
            </Tooltip>
            , an{' '}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  href="https://nextjump.com/"
                  className="font-normal text-indigo-1000 dark:text-mint-1100"
                >
                  american tech company
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                <p className="w-48">
                  In 2019, at NextJump I got the opportunity to work more with
                  PHP and databases, and I taught a weekly online class on HTML,
                  CSS and JavaScript to 500+ students.
                </p>
              </TooltipContent>
            </Tooltip>
            , and a{' '}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  href="https://www.behaviourlab.com/"
                  className="font-normal text-indigo-1000 dark:text-mint-1100"
                >
                  start-up
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                <p className="w-60">
                  I joined Behaviour Lab in my first full-time role in 2021,
                  where I learned how fun it is to work at a start up. The
                  ambiguity, the constant learning, the autonomy, the
                  responsiblity - there isn&apos;t a single place where
                  you&apos;d learn more.
                </p>
              </TooltipContent>
            </Tooltip>
            .
          </motion.div>
          <motion.div
            className="mb-4 text-lg font-light leading-normal text-gray-1100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.4,
              duration: 0.4,
              type: 'tween',
              ease: 'easeInOut',
            }}
          >
            Joining a startup was one of the best decisions I&apos;ve ever made.
            In two years and counting at Behaviour Lab, I&apos;ve gone from
            being one of the first engineers on the team to leading 8 engineers
            and 2 designers to build two complex data products. Together,
            we&apos;re changing the way asset managers make investment decisions
            through a combination of data analytics, behavioural science and
            engineering.
          </motion.div>
          <motion.div
            className="mb-4 text-lg font-light leading-normal text-gray-1100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.5,
              duration: 0.4,
              type: 'tween',
              ease: 'easeInOut',
            }}
          >
            When I&apos;m not at the computer, I&apos;m usually{' '}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  href="https://open.spotify.com/artist/4tCn4Wkn4cf0CWLUFvQO58"
                  className="font-normal text-indigo-1000 dark:text-mint-1100"
                >
                  writing music
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                <p className="w-60">
                  I&apos;ve been writing music since I was five, and some of its
                  over on Spotify! Have a listen.
                </p>
              </TooltipContent>
            </Tooltip>
            , running, or exploring london with my partner.
          </motion.div>
        </div>
      </TooltipProvider>
    </div>
  )
}

export default Hero
