import * as React from 'react'

import SocialLink from '../links/SocialLink'
import GitHubIcon from '@/icons/GitHubIcon'
import LinkedInIcon from '@/icons/LinkedInIcon'
import TwitterIcon from '@/icons/TwitterIcon'
import SpotifyIcon from '@/icons/SpotifyIcon'

const SocialLinks = () => {
  return (
    <div className="flex gap-5">
      <SocialLink
        href="https://github.com/yp717"
        altText="Link to Yannis Panagis GitHub"
        Icon={GitHubIcon}
      />
      <SocialLink
        href="https://uk.linkedin.com/in/yannis-panagis"
        altText="Link to Yannis Panagis LinkedIn!"
        Icon={LinkedInIcon}
      />
      <SocialLink
        href="https://twitter.com/yannispanagis"
        altText="Link to Yannis Panagis Twitter!"
        Icon={TwitterIcon}
      />
      <SocialLink
        href="https://open.spotify.com/artist/4tCn4Wkn4cf0CWLUFvQO58"
        altText="Link to Yannis Panagis Spotify!"
        Icon={SpotifyIcon}
      />
    </div>
  )
}

export default SocialLinks
