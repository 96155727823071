import * as React from 'react'

import Link from 'next/link'

import cn from '@/utils/cn'

interface ISocialLinkProps {
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  href: string
  children?: React.ReactNode
  altText?: string
  className?: string
}

const SocialLink = ({
  Icon,
  href,
  children,
  altText = '',
  className = '',
}: ISocialLinkProps) => {
  return (
    <Link
      href={href}
      className={cn(
        className,
        'flex gap-4 text-sm font-medium transition cursor-pointer fill-gray-1000 hover:fill-gray-1100',
      )}
      aria-label={altText}
      alt-text={altText}
    >
      <Icon
        className="flex-none w-6 h-6 transition fill-gray-1000 hover:fill-indigo-1000 dark:hover:fill-mint-1100"
        role="img"
      />
      {children && <span>{children}</span>}
    </Link>
  )
}

export default SocialLink
